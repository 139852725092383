import React, { useState, useEffect } from 'react';
import { Layout, Typography, Card, Input, Radio, Button, Row, Col, Space, Select, Image, Slider, message, Alert, Spin } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { trackPageView, trackFeatureUsage, trackStoryGeneration, trackError } from '../utils/posthog';
import { generateStorySummary } from '../utils/api';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

const PageContainer = styled(Content)`
  min-height: 100vh;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
`;

const HeaderBar = styled.div`
  height: 56px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  
  img {
    height: 40px;
    cursor: pointer;
    object-fit: contain;
    
    @media (min-width: 768px) {
      height: 48px;
    }
  }
`;

const MainContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 16px;
  
  @media (min-width: 768px) {
    padding: 32px 50px;
  }
`;

const FormContainer = styled.div`
  max-width: 900px;
  width: 100%;
`;

const HeaderTitle = styled(Title)`
  margin-bottom: 20px !important;
  color: #333333;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  font-size: 28px !important;
  
  @media (min-width: 768px) {
    font-size: 32px !important;
  }
  
  font-weight: 700 !important;
  line-height: 1.2 !important;
`;

const FormSection = styled.div`
  margin-bottom: 24px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionLabel = styled(Text)`
  display: block;
  font-size: 15px !important;
  
  @media (min-width: 768px) {
    font-size: 16px !important;
  }
  
  font-weight: 600 !important;
  color: #333333;
  margin-bottom: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
`;

const ThemeCard = styled(Card)`
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  height: 100%;
  border: none;
  background: transparent;

  ${props => props.selected && `
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 2px solid #1890ff;
    border-radius: 8px;
  `}

  &:hover {
    transform: ${props => props.selected ? 'scale(1.05)' : 'scale(1.02)'};
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .ant-card-body {
    padding: 12px 8px;
  }

  img {
    width: 100%;
    height: 120px;
    
    @media (min-width: 768px) {
      height: 140px;
    }
    
    object-fit: contain;
    margin-bottom: 8px;
    padding: 8px;
  }
`;

const themes = [
  { name: 'Animals', image: '/images/themes/animals.png' },
  { name: 'Nature', image: '/images/themes/nature.png' },
  { name: 'Dinosaurs', image: '/images/themes/dinosaurs.png' },
  { name: 'Unicorn', image: '/images/themes/unicorns.png' },
  { name: 'Cars', image: '/images/themes/cars.png' },
  { name: 'Space', image: '/images/themes/space.png' },
];

const StyledSlider = styled(Slider)`
  width: 100%;
  max-width: 300px;
  margin-top: 8px;

  @media (max-width: 480px) {
    max-width: 100%;
  }

  .ant-slider-mark-text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    font-size: 14px;
    color: #666666;
  }

  .ant-slider-handle {
    width: 20px;
    height: 20px;
    margin-top: -8px;
  }
`;

const FormControl = styled.div`
  width: 100%;
  max-width: 300px;
  
  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 300px;

  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

// Update the Radio Groups to be responsive
const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    gap: 12px;
  }

  .ant-radio-wrapper {
    margin-right: 0;
    font-size: 14px;
    
    @media (min-width: 768px) {
      font-size: 15px;
    }
  }
`;

// Add new styled component for the loading overlay
const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  gap: 16px;

  .loading-text {
    margin-top: 16px;
    font-size: 15px;
    
    @media (min-width: 768px) {
      font-size: 16px;
    }
    
    color: #333;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  }
`;

const BedtimeStory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [ageGroup, setAgeGroup] = useState('4-5');
  const [storyLength, setStoryLength] = useState('5');
  const [interest, setInterest] = useState('');
  const [educationalType, setEducationalType] = useState('none');
  const [religiousAngle, setReligiousAngle] = useState('none');
  const [selectedInterest, setSelectedInterest] = useState(null);
  const [customInterest, setCustomInterest] = useState('');
  const [error, setError] = useState(null);

  // Track page view when component mounts
  useEffect(() => {
    trackPageView('bedtime_story');
    
    // Remove the usage tracking from page load
    // We'll track it when they actually use the feature
  }, []);

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleGenerateStory = async () => {
    setLoading(true);
    setError(null);

    // Track the attempt to generate a story
    trackFeatureUsage('story_generation_attempt', {
      age_group: ageGroup,
      story_length: storyLength,
      interest: interest,
      educational_type: educationalType,
      religious_angle: religiousAngle
    });

    try {
      const storyData = {
        ageGroup,
        storyLength,
        interest: selectedInterest || customInterest,
        educationalElement: {
          type: educationalType,
          content: ''
        },
        religiousAngle,
      };

      console.log('Sending story data to server:', storyData);

      // Generate a summary of the story first
      const result = await generateStorySummary(storyData);
      console.log('Story generation result:', result);
      
      if (!result.success) {
        throw new Error(result.error || 'Failed to generate story');
      }
      
      const summary = result.data;
      
      // Track feature usage on successful generation
      const currentCount = parseInt(localStorage.getItem('featureUsageCount') || '0', 10);
      const newCount = currentCount + 1;
      localStorage.setItem('featureUsageCount', newCount.toString());
      
      // Store last path for popup navigation
      localStorage.setItem('lastIntendedPath', '/bedtime-story');
      
      // Check if we should show popup
      const hasSeenPopup = localStorage.getItem('hasSeenSubscribePopup') === 'true';
      if (newCount >= 3 && hasSeenPopup !== 'true') {
        console.log('Showing popup and saving story data');
        // We need to navigate to the landing page to show the popup
        // But first save the current state
        localStorage.setItem('pendingStoryData', JSON.stringify({
          ...storyData,
          ...summary
        }));
        navigate('/');
        return;
      }

      // Navigate to the story display page with the story data
      console.log('Navigating to story display with data:', summary);
      navigate('/story-display', {
        state: {
          story: summary
        }
      });

      // Track successful story generation
      trackStoryGeneration({
        age_group: ageGroup,
        story_length: storyLength,
        interest: selectedInterest || customInterest,
        educational_type: educationalType,
        religious_angle: religiousAngle,
        success: true
      });
    } catch (error) {
      console.error('Error generating story:', error);
      
      // Track error
      trackError('story_generation', error.message, {
        age_group: ageGroup,
        story_length: storyLength,
        interest: selectedInterest || customInterest,
        educational_type: educationalType,
        religious_angle: religiousAngle
      });
      
      setError('We couldn\'t create your story right now. Please try again in a moment.');
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      {loading && (
        <LoadingOverlay>
          <Spin size="large" />
          <div className="loading-text">
            Creating your bedtime story...
          </div>
        </LoadingOverlay>
      )}
      
      <HeaderBar>
        <LogoContainer>
          <img 
            src="/images/logo.png" 
            alt="ParentBox Logo" 
            onClick={handleLogoClick}
          />
        </LogoContainer>
      </HeaderBar>

      <MainContent>
        <FormContainer>
          <HeaderTitle level={2}>Generate Your Bedtime Story</HeaderTitle>
          
          {error && (
            <FormSection>
              <Alert
                message="Error"
                description={error}
                type="error"
                showIcon
                closable
                onClose={() => setError(null)}
              />
            </FormSection>
          )}

          <FormSection>
            <SectionLabel>Child's Age</SectionLabel>
            <FormControl>
              <Select
                style={{ width: '100%' }}
                value={ageGroup}
                onChange={setAgeGroup}
              >
                <Option value="0-2">0-2 years</Option>
                <Option value="2-3">2-3 years</Option>
                <Option value="4-5">4-5 years</Option>
                <Option value="6-7">6-7 years</Option>
                <Option value="7+">7+ years</Option>
              </Select>
            </FormControl>
          </FormSection>

          <FormSection>
            <SectionLabel>Educational Element</SectionLabel>
            <StyledRadioGroup
              value={educationalType}
              onChange={e => setEducationalType(e.target.value)}
            >
              <Radio value="none">None</Radio>
              <Radio value="fun-fact">Fun Fact</Radio>
              <Radio value="moral">Moral Lesson</Radio>
              <Radio value="vocabulary">New Word</Radio>
            </StyledRadioGroup>
          </FormSection>

          <FormSection>
            <SectionLabel>Religious Angle</SectionLabel>
            <StyledRadioGroup
              value={religiousAngle}
              onChange={e => setReligiousAngle(e.target.value)}
            >
              <Radio value="none">❌ None</Radio>
              <Radio value="christianity">✝️ Christianity</Radio>
              <Radio value="hinduism">🕉️ Hinduism</Radio>
              <Radio value="buddhism">☸️ Buddhism</Radio>
              <Radio value="islam">☪️ Islam</Radio>
              <Radio value="judaism">✡️ Judaism</Radio>
              <Radio value="capitalism">📈 Capitalism</Radio>
            </StyledRadioGroup>
          </FormSection>

          <FormSection>
            <SectionLabel>Choose an Interest (Optional)</SectionLabel>
            <Row gutter={[12, 12]}>
              {themes.map(theme => (
                <Col xs={12} sm={8} md={4} key={theme.name}>
                  <ThemeCard
                    selected={selectedInterest === theme.name}
                    onClick={() => {
                      setSelectedInterest(theme.name === selectedInterest ? null : theme.name);
                      setCustomInterest('');
                    }}
                  >
                    <img src={theme.image} alt={theme.name} />
                    <Text style={{ fontSize: '14px' }}>{theme.name}</Text>
                  </ThemeCard>
                </Col>
              ))}
            </Row>
            <StyledInput
              placeholder="Or enter another interest"
              value={customInterest}
              onChange={e => {
                setCustomInterest(e.target.value);
                setSelectedInterest(null);
              }}
              style={{ marginTop: 16 }}
            />
          </FormSection>

          <FormSection style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button
              type="primary"
              size="large"
              onClick={handleGenerateStory}
              loading={loading}
              disabled={!ageGroup}
              style={{ 
                width: '100%', 
                maxWidth: '300px',
                height: '44px'
              }}
            >
              Generate Story
            </Button>
          </FormSection>
        </FormContainer>
      </MainContent>
    </PageContainer>
  );
};

export default BedtimeStory;
