import React, { useState, useEffect } from 'react';
import { Typography, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SubscribePopup from '../components/SubscribePopup';

const { Content } = Layout;
const { Title, Text } = Typography;

const StyledContent = styled(Content)`
  background: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.ant-layout-content {
    background: #FFFFFF;
  }
`;

const HeaderSection = styled.div`
  padding: 16px;
  position: relative;
  
  @media (min-width: 768px) {
    padding: 16px 32px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  left: 16px;
  
  @media (min-width: 768px) {
    left: 32px;
  }

  img {
    height: 60px;
    object-fit: contain;
    
    @media (min-width: 768px) {
      height: 96px;
    }
  }
`;

const HeaderTextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: 80px;
  
  @media (min-width: 768px) {
    padding-top: 120px;
  }
`;

const HeaderTitle = styled(Title)`
  font-size: 36px !important;
  
  @media (min-width: 768px) {
    font-size: 48px !important;
  }
  
  color: #333333 !important;
  font-family: 'Open Sans', sans-serif;
  margin: 0 0 8px !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
`;

const HeaderTagline = styled(Text)`
  font-size: 16px;
  
  @media (min-width: 768px) {
    font-size: 18px;
  }
  
  color: #666666;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  font-weight: 400;
  display: block;
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 0;
  margin-top: 16px;
  
  @media (min-width: 768px) {
    padding: 0 0 48px;
    margin-top: 24px;
  }
  
  background: #FFFFFF;
`;

const CardsSection = styled.div`
  padding: 0 20px;
  
  @media (min-width: 768px) {
    padding: 0 50px;
  }
  
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
`;

const CardsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  margin: 0 auto;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  gap: 16px;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  
  @media (min-width: 768px) {
    flex-direction: row;
    padding: 32px;
    gap: 20px;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  @media (min-width: 768px) {
    align-items: flex-start;
    text-align: left;
  }
`;

const CardTitle = styled(Title)`
  color: #333333 !important;
  font-family: 'Open Sans', sans-serif;
  margin: 0 0 8px !important;
  font-size: 24px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  text-align: center;
  
  @media (min-width: 768px) {
    font-size: 28px !important;
    text-align: left;
  }
`;

const CardDescription = styled(Text)`
  color: #666666;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  display: block;
  text-align: center;
  font-weight: 400;
  
  @media (min-width: 768px) {
    text-align: left;
  }
`;

const CardImage = styled.div`
  width: 100%;
  max-width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  
  @media (min-width: 768px) {
    flex: 0 0 250px;
    width: 250px;
    height: 250px;
    margin-bottom: 0;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;

const StyledFooter = styled.div`
  width: 100%;
  margin-top: 24px;
  
  @media (min-width: 768px) {
    margin-top: 32px;
  }
`;

const FooterContent = styled.footer`
  text-align: center;
  padding: 16px;
  color: #333333;
  font-family: 'Open Sans', sans-serif;
  
  @media (min-width: 768px) {
    padding: 24px 0;
  }
  
  a {
    color: #007BFF;
    font-size: 16px;
    text-decoration: none;
    display: inline-block;
    padding: 4px 8px;
    font-family: 'Open Sans', sans-serif;
    
    @media (min-width: 768px) {
      font-size: 18px;
      padding: 4px 0;
    }
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Landing = () => {
  const navigate = useNavigate();
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  
  // Check if user has already seen the popup and if we should show it
  useEffect(() => {
    const hasSeenPopup = localStorage.getItem('hasSeenSubscribePopup') === 'true';
    const usageCount = parseInt(localStorage.getItem('featureUsageCount') || '0', 10);
    
    console.log('Landing page loaded. Usage count:', usageCount, 'Has seen popup:', hasSeenPopup);
    
    // Changed threshold back to 3
    if (!hasSeenPopup && usageCount >= 3) {
      console.log('Showing popup based on usage count');
      setShowSubscribePopup(true);
    } else if (hasSeenPopup) {
      // Check if we have pending state to restore
      const pendingExplanation = localStorage.getItem('pendingExplanation');
      const pendingStoryData = localStorage.getItem('pendingStoryData');
      
      if (pendingExplanation) {
        console.log('Restoring pending explanation');
        localStorage.removeItem('pendingExplanation');
        navigate('/kid-explainer', { state: { explanation: pendingExplanation } });
      } else if (pendingStoryData) {
        console.log('Restoring pending story data');
        localStorage.removeItem('pendingStoryData');
        navigate('/story-display', { state: { storyData: JSON.parse(pendingStoryData) } });
      }
    }
  }, [navigate]);
  
  // We don't need incrementUsageAndNavigate anymore since we're tracking
  // usage in the feature components themselves
  
  const handleBedtimeStoryClick = () => {
    navigate('/bedtime-story');
  };

  const handleKidExplainerClick = () => {
    navigate('/kid-explainer');
  };
  
  const handleClosePopup = () => {
    console.log('Popup closed, marking as seen');
    // Mark that user has seen the popup
    localStorage.setItem('hasSeenSubscribePopup', 'true');
    setShowSubscribePopup(false);
    
    // Get the last intended destination
    const lastPath = localStorage.getItem('lastIntendedPath') || '/';
    
    // Check if we have pending state to restore
    const pendingExplanation = localStorage.getItem('pendingExplanation');
    const pendingStoryData = localStorage.getItem('pendingStoryData');
    
    console.log('Pending data:', { pendingExplanation: !!pendingExplanation, pendingStoryData: !!pendingStoryData, lastPath });
    
    if (pendingExplanation) {
      console.log('Navigating to kid explainer with explanation');
      localStorage.removeItem('pendingExplanation');
      navigate('/kid-explainer', { state: { explanation: pendingExplanation } });
    } else if (pendingStoryData) {
      console.log('Navigating to story display with story data');
      localStorage.removeItem('pendingStoryData');
      navigate('/story-display', { state: { storyData: JSON.parse(pendingStoryData) } });
    } else {
      console.log('Navigating to last path:', lastPath);
      navigate(lastPath);
    }
  };

  return (
    <StyledContent>
      <HeaderSection>
        <LogoContainer>
          <img src="/images/logo.png" alt="Parents Box Logo" />
        </LogoContainer>
        <HeaderTextSection>
          <HeaderTitle level={1}>Parents Box</HeaderTitle>
          <HeaderTagline>AI Tools to Help Make Your Kids Smarter</HeaderTagline>
        </HeaderTextSection>
      </HeaderSection>
      
      <MainContent>
        <CardsSection>
          <CardsRow>
            <Card onClick={handleBedtimeStoryClick}>
              <CardImage>
                <img 
                  src="/images/homepage_bedtime.png" 
                  alt="Parent reading to child" 
                />
              </CardImage>
              <CardContent>
                <CardTitle level={2}>Bedtime Stories</CardTitle>
                <CardDescription>
                  Personalized, educational tales in seconds.
                </CardDescription>
              </CardContent>
            </Card>

            <Card onClick={handleKidExplainerClick}>
              <CardImage>
                <img 
                  src="/images/homepage_explainer.png" 
                  alt="Child asking questions" 
                />
              </CardImage>
              <CardContent>
                <CardTitle level={2}>Kid Explainer</CardTitle>
                <CardDescription>
                  Answer those endless questions—instantly!
                </CardDescription>
              </CardContent>
            </Card>
          </CardsRow>
        </CardsSection>
      </MainContent>

      <StyledFooter>
        <FooterContent>
          <a href="mailto:theparentsbox@gmail.com">Contact Us</a>
        </FooterContent>
      </StyledFooter>
      
      {/* Subscribe Popup */}
      <SubscribePopup 
        visible={showSubscribePopup} 
        onClose={handleClosePopup} 
      />
    </StyledContent>
  );
};

export default Landing;