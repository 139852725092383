import React, { useState, useEffect } from 'react';
import { Layout, Typography, Button, List, Space, message, Alert } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { trackPageView, trackFeatureUsage, trackStoryGeneration, trackError } from '../utils/posthog';
import { generateFullStory } from '../utils/api';

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

const PageContainer = styled(Content)`
  min-height: 100vh;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
`;

const HeaderBar = styled.div`
  height: 56px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  
  img {
    height: 40px;
    cursor: pointer;
    object-fit: contain;
    
    @media (min-width: 768px) {
      height: 48px;
    }
  }
`;

const MainContent = styled.div`
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
  padding: 16px;
  
  @media (min-width: 768px) {
    padding: 32px 24px;
  }
`;

const StoryContainer = styled.div`
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 16px;
  
  @media (min-width: 768px) {
    padding: 32px;
  }
`;

const StoryTitle = styled(Title)`
  font-size: 24px !important;
  line-height: 1.3 !important;
  
  @media (min-width: 768px) {
    font-size: 28px !important;
  }
  
  color: #333333 !important;
  margin-bottom: 16px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
`;

const OutlineSection = styled.div`
  margin: 24px 0;

  .ant-list-item {
    padding: 8px 0;
    color: #444444;
    font-size: 16px;
    line-height: 1.5;
  }
`;

const StoryText = styled(Paragraph)`
  color: #444444;
  font-size: 15px;
  
  @media (min-width: 768px) {
    font-size: 16px;
  }
  
  line-height: 1.6;
  margin: 24px 0;
  white-space: pre-line;
`;

const EducationalSnippet = styled.div`
  margin: 16px 0;
  padding: 12px;
  
  @media (min-width: 768px) {
    margin: 24px 0;
    padding: 16px;
  }
  
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  background: #F9FAFB;

  .snippet-title {
    font-size: 15px;
    
    @media (min-width: 768px) {
      font-size: 16px;
    }
    
    font-weight: 600;
    color: #333333;
    margin-bottom: 8px;
  }

  .snippet-content {
    color: #666666;
    font-size: 14px;
    
    @media (min-width: 768px) {
      font-size: 15px;
    }
    
    line-height: 1.5;
  }
`;

const SummaryText = styled(Paragraph)`
  color: #444444;
  font-size: 15px;
  
  @media (min-width: 768px) {
    font-size: 16px;
  }
  
  line-height: 1.6;
  margin: 24px 0;
  white-space: pre-line;
  font-style: italic;
  background: #F9FAFB;
  padding: 16px;
  border-radius: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 32px;
  flex-wrap: wrap;
  
  @media (max-width: 480px) {
    flex-direction: column;
    width: 100%;
    
    .ant-btn {
      width: 100%;
      min-height: 44px;
    }
  }
`;

const StoryDisplay = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [generatingFullStory, setGeneratingFullStory] = useState(false);
  const [error, setError] = useState(null);
  
  // Debug log to see what we're getting
  console.log('Location state:', location.state);
  
  // Check both possible locations for story data
  const story = location.state?.story || location.state?.storyData || {
    title: 'Story Not Found',
    summary: 'Please go back and generate a new story.',
    educationalElement: null,
    religiousValue: null,
    religiousAngle: 'none',
    isFullStory: false
  };

  console.log('Using story data:', story);

  // Initialize processedStory with the story data
  const [processedStory, setProcessedStory] = useState(story);

  // Process the story to remove duplicate title if needed
  useEffect(() => {
    if (story.isFullStory && story.fullStory) {
      // Use the processStoryContent function for consistency
      const processedStoryData = processStoryContent(story);
      setProcessedStory(processedStoryData);
    } else {
      // For summary view, ensure educational element is displayed
      if (story.educationalElement && story.educationalElement.type !== 'none') {
        // If there's no content, add a placeholder
        if (!story.educationalElement.content) {
          const updatedStory = {
            ...story,
            educationalElement: {
              ...story.educationalElement,
              content: 'Loading educational content...'
            }
          };
          setProcessedStory(updatedStory);
          console.log('Added placeholder for educational content');
        } else {
          setProcessedStory(story);
        }
      } else {
        setProcessedStory(story);
      }
    }
  }, [story]);

  // Track page view when component mounts
  useEffect(() => {
    trackPageView('story_display');
    
    // Track if this is a summary or full story view
    trackFeatureUsage('story_view', {
      story_type: story.isFullStory ? 'full_story' : 'summary',
      has_educational_element: !!story.educationalElement,
      has_religious_value: !!story.religiousValue,
      religious_angle: story.religiousAngle
    });
  }, [story.isFullStory, story.educationalElement, story.religiousValue, story.religiousAngle]);

  // Debug logs for story elements
  console.log('Educational element:', story.educationalElement);
  console.log('Religious value:', story.religiousValue);
  console.log('Religious angle:', story.religiousAngle);

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleBackToForm = () => {
    navigate('/bedtime-story');
  };

  const handleRegenerate = () => {
    navigate('/bedtime-story', { 
      state: { regenerate: true } 
    });
  };

  const handleGenerateFullStory = async () => {
    setGeneratingFullStory(true);
    setError(null);
    console.log('Starting full story generation...');

    // Track the attempt to generate a full story
    trackFeatureUsage('full_story_generation_attempt', {
      title: story.title,
      summary_length: story.summary.length
    });

    try {
      // Pass the complete story object instead of just the summary text
      console.log('Sending request to generate full story with story object:', story);
      const result = await generateFullStory(story);
      console.log('Full story generation response:', result);
      
      if (!result.success) {
        console.error('Server returned error:', result.error);
        throw new Error(result.error || 'Failed to generate full story');
      }

      // Log the structure of the response data
      console.log('Response data structure:', {
        hasData: !!result.data,
        dataKeys: result.data ? Object.keys(result.data) : [],
        fullStoryExists: result.data && !!result.data.fullStory,
        fullStoryLength: result.data && result.data.fullStory ? result.data.fullStory.length : 0
      });

      // Handle different response formats
      let fullStoryData;
      if (result.data && result.data.fullStory) {
        // New format with data property
        fullStoryData = result.data;
        console.log('Using data.fullStory from response');
      } else if (result.fullStory) {
        // Old format without data property
        fullStoryData = result;
        console.log('Using fullStory directly from response');
      } else {
        console.error('Missing fullStory in response:', result);
        throw new Error('The server response did not contain a story. Please try again.');
      }

      // Track successful full story generation
      trackStoryGeneration({
        story_type: 'full',
        title: story.title,
        summary_length: story.summary.length,
        full_story_length: fullStoryData.fullStory.length
      });

      console.log('Creating updated story object with full story');
      
      // Create a new story object with the full story
      const updatedStory = {
        ...story,
        fullStory: fullStoryData.fullStory,
        isFullStory: true
      };
      
      console.log('Updated story object:', {
        hasFullStory: !!updatedStory.fullStory,
        fullStoryLength: updatedStory.fullStory ? updatedStory.fullStory.length : 0,
        isFullStory: updatedStory.isFullStory
      });
      
      // Process the story content to remove duplicate titles
      const processedStoryData = processStoryContent(updatedStory);
      
      // Set the processed story
      setProcessedStory(processedStoryData);
      
      // Force a re-render by updating a key in the story object
      setTimeout(() => {
        console.log('Forcing re-render...');
        setProcessedStory(prev => ({
          ...prev,
          _forceUpdate: Date.now()
        }));
      }, 100);
      
      console.log('Full story generation and processing complete');
    } catch (err) {
      console.error('Error generating full story:', err);
      
      // Provide more specific error messages based on the error
      let errorMessage = 'Failed to generate the full story. Please try again.';
      
      if (err.message.includes('timed out')) {
        errorMessage = 'The request timed out. Our story service might be busy. Please try again in a moment.';
      } else if (err.message.includes('popular right now')) {
        errorMessage = 'Our story creator is very popular right now! Please wait a moment and try again.';
      }
      
      setError(errorMessage);
      
      // Track error
      trackError('full_story_generation_error', {
        error_message: err.message,
        title: story.title
      });
    } finally {
      setGeneratingFullStory(false);
    }
  };

  // Add a helper function to process the story content
  const processStoryContent = (storyData) => {
    if (!storyData) return;
    
    console.log('Processing story content:', {
      hasFullStory: !!storyData.fullStory,
      isFullStory: storyData.isFullStory,
      title: storyData.title
    });
    
    // Create a processed version of the story with the title removed from the full story
    if (storyData.fullStory && storyData.title) {
      let processedFullStory = storyData.fullStory;
      const title = storyData.title.trim();
      
      // Handle the specific case where title appears twice (once plain, once markdown)
      const doubleTitlePattern = new RegExp(`^\\s*${title}\\s*\\n+\\s*#\\s*${title}\\s*(?:\\n|$)`, 'i');
      if (doubleTitlePattern.test(processedFullStory)) {
        // If we find this pattern, remove both titles and keep the rest
        processedFullStory = processedFullStory.replace(doubleTitlePattern, '');
      } else {
        // If the double pattern isn't found, try individual patterns
        
        // Check for and remove the plain title at the beginning
        const plainTitlePattern = new RegExp(`^\\s*${title}\\s*(?:\\n|$)`, 'i');
        if (plainTitlePattern.test(processedFullStory)) {
          processedFullStory = processedFullStory.replace(plainTitlePattern, '');
        }
        
        // Check for and remove any markdown title format (# Title)
        const markdownTitlePattern = new RegExp(`^\\s*#\\s*${title}\\s*(?:\\n|$)`, 'i');
        if (markdownTitlePattern.test(processedFullStory)) {
          processedFullStory = processedFullStory.replace(markdownTitlePattern, '');
        } else {
          // If specific title not found, try generic markdown heading
          const genericMarkdownPattern = /^\s*#\s*.*?\n/;
          if (genericMarkdownPattern.test(processedFullStory)) {
            processedFullStory = processedFullStory.replace(genericMarkdownPattern, '');
          }
        }
      }
      
      // Trim any extra whitespace at the beginning
      processedFullStory = processedFullStory.trim();
      
      return {
        ...storyData,
        fullStory: processedFullStory
      };
    }
    
    return storyData;
  };

  // Debug logs for component state
  console.log('Component state:', {
    processedStory: processedStory ? {
      title: processedStory.title,
      hasFullStory: !!processedStory.fullStory,
      fullStoryLength: processedStory.fullStory ? processedStory.fullStory.length : 0,
      isFullStory: processedStory.isFullStory
    } : null,
    loading,
    generatingFullStory,
    error
  });

  if (!processedStory) {
    return null;
  }

  return (
    <PageContainer>
      <HeaderBar>
        <LogoContainer>
          <img 
            src="/images/logo.png" 
            alt="ParentBox Logo" 
            onClick={handleLogoClick}
          />
        </LogoContainer>
      </HeaderBar>

      <MainContent>
        <StoryContainer>
          <StoryTitle level={2}>{processedStory.title}</StoryTitle>

          {processedStory.educationalElement && processedStory.educationalElement.type !== 'none' && (
            <EducationalSnippet>
              <div className="snippet-title">
                {processedStory.educationalElement.type === 'fun-fact' && 'Fun Fact'}
                {processedStory.educationalElement.type === 'moral' && 'Moral of the Story'}
                {processedStory.educationalElement.type === 'vocabulary' && 'New Word to Learn'}
              </div>
              <div className="snippet-content">
                {processedStory.educationalElement.content || 'Loading educational content...'}
              </div>
            </EducationalSnippet>
          )}

          {processedStory.religiousAngle !== 'none' && processedStory.religiousValue && (
            <EducationalSnippet>
              <div className="snippet-title">
                {processedStory.religiousAngle === 'capitalism' 
                  ? 'Key Economic Concept' 
                  : `Key Value from ${processedStory.religiousAngle.charAt(0).toUpperCase() + processedStory.religiousAngle.slice(1)}`}
              </div>
              <div className="snippet-content">
                {processedStory.religiousValue}
              </div>
            </EducationalSnippet>
          )}

          {!processedStory.isFullStory && processedStory.summary && (
            <SummaryText>
              {processedStory.summary}
            </SummaryText>
          )}

          {processedStory.isFullStory && processedStory.fullStory ? (
            <>
              <StoryText>
                {console.log('Rendering full story, length:', processedStory.fullStory.length)}
                {processedStory.fullStory}
              </StoryText>
            </>
          ) : processedStory.isFullStory && (
            <div>
              {console.log('isFullStory is true but fullStory is missing')}
              <Alert 
                message="Story content could not be displayed" 
                description="Please try generating the story again." 
                type="warning" 
                showIcon 
              />
            </div>
          )}

          <ButtonContainer>
            {!processedStory.isFullStory && (
              <Button 
                type="primary" 
                size="large"
                onClick={handleGenerateFullStory}
                loading={generatingFullStory}
              >
                Show Full Story
              </Button>
            )}
            <Button 
              type={processedStory.isFullStory ? "primary" : "default"}
              size="large"
              onClick={handleRegenerate}
            >
              Generate New Version
            </Button>
            <Button 
              size="large"
              onClick={handleBackToForm}
            >
              Back to Form
            </Button>
          </ButtonContainer>
        </StoryContainer>
      </MainContent>
    </PageContainer>
  );
};

export default StoryDisplay; 