// API utility to handle API requests with the correct base URL

// Determine the base URL for API requests
const getBaseUrl = () => {
  // In production, use the environment variable or default to the current origin
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_API_URL || window.location.origin;
  }
  // In development, use relative URLs (which will be proxied by Create React App)
  return '';
};

// Helper function to build the full API URL
const buildApiUrl = (endpoint) => {
  const baseUrl = getBaseUrl();
  // Make sure endpoint starts with a slash
  const formattedEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;
  return `${baseUrl}${formattedEndpoint}`;
};

// Generic fetch function with error handling
export const apiRequest = async (endpoint, options = {}) => {
  try {
    const url = buildApiUrl(endpoint);
    console.log(`Making API request to ${endpoint}`);
    
    // Set default headers if not provided
    const headers = options.headers || {};
    if (!headers['Content-Type'] && !(options.body instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
    }
    
    const response = await fetch(url, {
      ...options,
      headers,
    });
    
    console.log(`API response received from ${endpoint}:`, {
      status: response.status,
      ok: response.ok,
      statusText: response.statusText
    });
    
    // For non-204 responses, try to parse JSON
    if (response.status !== 204) {
      try {
        const data = await response.json();
        console.log(`Parsed JSON data from ${endpoint}:`, data);
        
        if (!response.ok) {
          console.error(`API error from ${endpoint}:`, {
            status: response.status,
            error: data.details || data.message || data.error || 'An error occurred'
          });
          return {
            success: false,
            data: null,
            error: data.details || data.message || data.error || 'An error occurred',
            status: response.status,
          };
        }
        
        // If the response has a 'data' property, return that directly
        if (data.data) {
          console.log(`Response contains data property, returning:`, data);
          return {
            success: true,
            data: data.data,
            error: null,
            status: response.status,
          };
        }
        
        // Otherwise, return the whole response as data
        return {
          success: true,
          data,
          error: null,
          status: response.status,
        };
      } catch (jsonError) {
        console.error(`Error parsing JSON from ${endpoint}:`, jsonError);
        return {
          success: false,
          data: null,
          error: 'Invalid response format',
          status: response.status,
        };
      }
    }
    
    // For 204 responses (no content)
    return {
      success: true,
      data: null,
      error: null,
      status: response.status,
    };
  } catch (error) {
    console.error(`Network error for ${endpoint}:`, error);
    return {
      success: false,
      data: null,
      error: error.message || 'Network error',
      status: 0,
    };
  }
};

// Specific API functions
export const generateStorySummary = async (storyData) => {
  // Always set storyLength to 5 minutes
  const optimizedStoryData = {
    ...storyData,
    storyLength: '5',
    // Ensure educationalElement is properly formatted
    educationalElement: storyData.educationalElement || { 
      type: storyData.educationalType || 'none', 
      content: '' 
    }
  };
  
  // Remove any legacy educationalType field if it exists
  if ('educationalType' in optimizedStoryData) {
    delete optimizedStoryData.educationalType;
  }
  
  console.log('Sending optimized story data to server:', optimizedStoryData);
  
  const result = await apiRequest('/api/bedtime-story/generate', {
    method: 'POST',
    body: JSON.stringify(optimizedStoryData),
  });
  
  console.log('Story generation API response:', result);
  
  return result;
};

export const generateFullStory = async (story) => {
  // Ensure the story is an object
  const storyData = story || {};
  
  const sanitizedSummary = {
    title: storyData.title || 'Untitled Story',
    summary: storyData.summary || '',
    // Handle educational element properly - maintain 'none' type if that's what was selected
    educationalElement: storyData.educationalElement ? 
      {
        type: storyData.educationalElement.type || 'none',
        content: storyData.educationalElement.content || ''
      } : 
      { type: 'none', content: '' },
    religiousAngle: storyData.religiousAngle || 'none',
    religiousValue: storyData.religiousValue || null,
    storyLength: storyData.storyLength || '5'
  };
  
  console.log('Sending sanitized summary to server:', sanitizedSummary);
  
  return apiRequest('/api/bedtime-story/full', {
    method: 'POST',
    body: JSON.stringify({ summary: sanitizedSummary }),
  });
};

export const generateExplanation = async (explanationData) => {
  return apiRequest('/api/kid-explainer/explain', {
    method: 'POST',
    body: JSON.stringify(explanationData),
  });
}; 