import React, { useEffect } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
  }
  
  .ant-modal-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #eaeaea;
    padding: 16px 24px;
  }
  
  .ant-modal-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  
  .ant-modal-body {
    padding: 24px;
  }
  
  .ant-modal-close {
    color: #666;
  }
`;

const MailchimpFormContainer = styled.div`
  .mc-embedded-subscribe-form {
    padding: 0 !important;
  }
  
  #mc_embed_signup {
    background: #fff;
    font: 14px Helvetica, Arial, sans-serif;
    width: 100%;
    margin: 0 auto;
    clear: both;
  }
  
  #mc_embed_signup h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 24px;
    color: #333;
    text-align: center;
    line-height: 1.4;
  }
  
  /* Hide the indicates-required section completely */
  .indicates-required {
    display: none !important;
  }
  
  .mc-field-group {
    width: 100% !important;
    padding-bottom: 16px;
  }
  
  .mc-field-group label {
    font-weight: 600;
    margin-bottom: 8px;
    display: block;
    color: #333;
  }
  
  .mc-field-group input {
    width: 100% !important;
    padding: 10px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    font-size: 16px;
    transition: all 0.3s;
  }
  
  .mc-field-group input:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: none;
  }
  
  #mc-embedded-subscribe {
    background-color: #1890ff !important;
    width: 300px !important;
    margin: 0 auto !important;
    height: 44px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 6px !important;
    cursor: pointer;
    transition: all 0.3s;
    display: block !important;
  }
  
  #mc-embedded-subscribe:hover {
    background-color: #096dd9 !important;
  }
  
  .optionalParent {
    width: 100% !important;
    margin-top: 16px !important;
    display: flex !important;
    justify-content: center !important;
  }
  
  .clear.foot {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  /* Override Mailchimp's default styles */
  #mc_embed_signup .button {
    width: 300px !important;
    margin: 0 auto !important;
    display: block !important;
  }
  
  #mc_embed_signup .clear {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
  }
  
  .optionalParent p {
    display: none !important;
  }
  
  #mce-responses {
    margin-top: 12px;
  }
  
  #mce-error-response, #mce-success-response {
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 12px;
  }
  
  #mce-error-response {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
    color: #ff4d4f;
  }
  
  #mce-success-response {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    color: #52c41a;
  }
`;

const SubscribePopup = ({ visible, onClose }) => {
  useEffect(() => {
    // Load Mailchimp validation script when the modal is visible
    if (visible) {
      const script = document.createElement('script');
      script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
      script.async = true;
      document.body.appendChild(script);
      
      // Initialize Mailchimp form validation
      script.onload = () => {
        if (window.jQuery) {
          window.fnames = new Array();
          window.ftypes = new Array();
          window.fnames[0] = 'EMAIL';
          window.ftypes[0] = 'email';
          window.jQuery.noConflict(true);
        }
      };
      
      return () => {
        // Clean up script when component unmounts
        if (script.parentNode) {
          document.body.removeChild(script);
        }
      };
    }
  }, [visible]);

  return (
    <StyledModal
      title="Join Our Community"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={650}
      centered
    >
      <MailchimpFormContainer>
        <link 
          href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" 
          rel="stylesheet" 
          type="text/css" 
        />
        <div id="mc_embed_signup">
          <form 
            action="https://gmail.us19.list-manage.com/subscribe/post?u=96e14fa9021825afd526f87a2&amp;id=92dc50eaa6&amp;f_id=00fa3ee7f0" 
            method="post" 
            id="mc-embedded-subscribe-form" 
            name="mc-embedded-subscribe-form" 
            className="validate" 
            target="_blank"
          >
            <div id="mc_embed_signup_scroll">
              <h2>Keep Access to the features for free —Enter Your Email</h2>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Email Address <span className="asterisk">*</span>
                </label>
                <input 
                  type="email" 
                  name="EMAIL" 
                  className="required email" 
                  id="mce-EMAIL" 
                  required 
                  defaultValue="" 
                  placeholder="your@email.com"
                />
              </div>
              <div id="mce-responses" className="clear foot">
                <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
              </div>
              <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                <input 
                  type="text" 
                  name="b_96e14fa9021825afd526f87a2_92dc50eaa6" 
                  tabIndex="-1" 
                  defaultValue="" 
                />
              </div>
              <div className="optionalParent">
                <div className="clear foot" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <input 
                    type="submit" 
                    name="subscribe" 
                    id="mc-embedded-subscribe" 
                    className="button" 
                    value="Subscribe" 
                    style={{ 
                      width: '300px', 
                      margin: '0 auto', 
                      display: 'block',
                      backgroundColor: '#1890ff'
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </MailchimpFormContainer>
    </StyledModal>
  );
};

export default SubscribePopup; 