import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import BedtimeStory from './pages/BedtimeStory';
import StoryDisplay from './pages/StoryDisplay';
import SchoolEventsReminder from './pages/SchoolEventsReminder';
import Landing from './pages/Landing';
import KidExplainer from './pages/KidExplainer';

const RoutesComponent = () => (
    <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/bedtime-story" element={<BedtimeStory />} />
        <Route path="/story-display" element={<StoryDisplay />} />
        <Route path="/school-events" element={<SchoolEventsReminder />} />
        <Route path="/kid-explainer" element={<KidExplainer />} />
    </Routes>
);

export default RoutesComponent;
