import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import Routes from './routes';
import { AuthProvider } from './context/AuthContext';
import styled from 'styled-components';
import { initPostHog } from './utils/posthog';
import DevTools from './utils/DevTools';

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  &.ant-layout {
    background: #FFFFFF;
  }
`;

// Component to track page views and handle analytics opt-out
const PageViewTracker = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Initialize PostHog when the app loads
    initPostHog();
    
    // Add keyboard shortcut for opting out of analytics (Ctrl+Shift+X)
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.shiftKey && e.key === 'X') {
        if (window.posthog) {
          if (window.posthog.has_opted_out_capturing()) {
            window.posthog.opt_in_capturing();
            console.log('PostHog tracking enabled');
          } else {
            window.posthog.opt_out_capturing();
            console.log('PostHog tracking disabled');
          }
        }
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return null;
};

const AppContent = () => {
    return (
        <StyledLayout style={{ minHeight: '100vh' }}>
            <PageViewTracker />
            <Content className="site-content" style={{ background: '#FFFFFF' }}>
                <Routes />
            </Content>
            {/* Only renders in development mode */}
            <DevTools />
        </StyledLayout>
    );
};

const App = () => (
    <Router>
        <AuthProvider>
            <AppContent />
        </AuthProvider>
    </Router>
);

export default App;