import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { markAsDeveloper } from './posthog';

const DevPanel = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 9999;
  display: ${props => props.visible ? 'block' : 'none'};
`;

const Button = styled.button`
  background-color: ${props => props.active ? '#4CAF50' : '#f44336'};
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 5px;
  font-size: 12px;
`;

const DevTools = () => {
  const [visible, setVisible] = useState(false);
  const [trackingEnabled, setTrackingEnabled] = useState(true);
  
  useEffect(() => {
    // Only show in development
    if (process.env.NODE_ENV !== 'development') {
      return;
    }
    
    // Add keyboard shortcut to toggle panel (Ctrl+Shift+D)
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.shiftKey && e.key === 'D') {
        setVisible(prev => !prev);
      }
    };
    
    // Check if tracking is already disabled
    if (window.posthog && window.posthog.has_opted_out_capturing()) {
      setTrackingEnabled(false);
    }
    
    // Check if developer flag is set
    const devFlag = localStorage.getItem('posthog_is_developer');
    if (devFlag === 'true') {
      setTrackingEnabled(false);
    }
    
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  const toggleTracking = () => {
    if (window.posthog) {
      if (trackingEnabled) {
        // Mark as developer and disable tracking
        markAsDeveloper(true);
        setTrackingEnabled(false);
      } else {
        // Unmark as developer and enable tracking
        markAsDeveloper(false);
        setTrackingEnabled(true);
      }
    }
  };
  
  // Only render in development
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }
  
  return (
    <DevPanel visible={visible}>
      <div>Development Tools</div>
      <div>
        <small>Analytics Tracking: {trackingEnabled ? 'ON' : 'OFF'}</small>
        <Button 
          active={trackingEnabled} 
          onClick={toggleTracking}
        >
          {trackingEnabled ? 'Disable Tracking' : 'Enable Tracking'}
        </Button>
      </div>
      <div style={{ marginTop: '10px', fontSize: '10px' }}>
        <small>Press Ctrl+Shift+D to toggle this panel</small>
      </div>
    </DevPanel>
  );
};

export default DevTools; 