// No need to import posthog-js since we're using the global instance from the script tag

// Check if PostHog is available globally
const isPostHogAvailable = () => {
  return typeof window !== 'undefined' && window.posthog;
};

// Function to check if the current user is a developer
const isDeveloper = () => {
  // Check if running in development mode
  const isDev = process.env.NODE_ENV === 'development';
  
  // Check if a developer flag is set in localStorage
  const devFlag = localStorage.getItem('posthog_is_developer');
  
  return isDev || devFlag === 'true';
};

// Set developer status in localStorage (call this once to mark your browser)
export const markAsDeveloper = (isDev = true) => {
  localStorage.setItem('posthog_is_developer', isDev.toString());
  
  // Opt out of tracking if marked as developer
  if (isDev && isPostHogAvailable()) {
    window.posthog.opt_out_capturing();
    console.log('PostHog tracking disabled for developer');
  } else if (isPostHogAvailable()) {
    window.posthog.opt_in_capturing();
    console.log('PostHog tracking enabled');
  }
};

// Expose the function globally for console access
if (typeof window !== 'undefined') {
  window.markAsDeveloper = markAsDeveloper;
  
  // Also add a function to check current status
  window.checkAnalyticsStatus = () => {
    if (isPostHogAvailable()) {
      const isOptedOut = window.posthog.has_opted_out_capturing();
      const isDev = isDeveloper();
      console.log(`Analytics Status:
- Tracking Enabled: ${!isOptedOut}
- Developer Mode: ${isDev}
- Environment: ${process.env.NODE_ENV}

To disable tracking: markAsDeveloper(true)
To enable tracking: markAsDeveloper(false)`);
    } else {
      console.log('PostHog is not available');
    }
  };
}

// No need to initialize PostHog as it's already initialized in the HTML
export const initPostHog = () => {
  // This function is kept for backward compatibility
  // but doesn't need to do anything since PostHog is initialized via script tag
  if (!isPostHogAvailable()) {
    console.warn('PostHog is not available. Make sure the script is loaded correctly.');
    return;
  }
  
  // Check if user is a developer and opt out if needed
  if (isDeveloper()) {
    window.posthog.opt_out_capturing();
    console.log('PostHog tracking disabled for developer');
  }
};

// Helper functions for tracking events
export const trackPageView = (pageName) => {
  if (!isPostHogAvailable() || isDeveloper()) return;
  window.posthog.capture('$pageview', { page: pageName });
};

export const trackFeatureUsage = (featureName, properties = {}) => {
  if (!isPostHogAvailable() || isDeveloper()) return;
  window.posthog.capture(`feature_used`, {
    feature: featureName,
    ...properties,
  });
};

export const trackStoryGeneration = (properties = {}) => {
  if (!isPostHogAvailable() || isDeveloper()) return;
  window.posthog.capture('story_generated', properties);
};

export const trackExplanationGeneration = (properties = {}) => {
  if (!isPostHogAvailable() || isDeveloper()) return;
  window.posthog.capture('explanation_generated', properties);
};

export const trackError = (errorType, errorMessage, properties = {}) => {
  if (!isPostHogAvailable() || isDeveloper()) return;
  window.posthog.capture('error_occurred', {
    error_type: errorType,
    error_message: errorMessage,
    ...properties,
  });
};

// Export the global PostHog instance for direct use if needed
export default isPostHogAvailable() ? window.posthog : { capture: () => {} }; 