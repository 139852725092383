import React, { useState, useEffect } from 'react';
import { Layout, Typography, Input, Button, Slider, Space, message, Alert } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { trackPageView, trackFeatureUsage, trackExplanationGeneration, trackError } from '../utils/posthog';

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

const PageContainer = styled(Content)`
  min-height: 100vh;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
`;

const HeaderBar = styled.div`
  height: 56px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  
  img {
    height: 40px;
    cursor: pointer;
    object-fit: contain;
    
    @media (min-width: 768px) {
      height: 48px;
    }
  }
`;

const MainContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding: 16px;
  
  @media (min-width: 768px) {
    padding: 32px 24px;
  }
`;

const FormContainer = styled.div`
  max-width: 600px;
  width: 100%;
`;

const HeaderTitle = styled(Title)`
  margin-bottom: 20px !important;
  color: #333333;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  font-size: 28px !important;
  
  @media (min-width: 768px) {
    font-size: 32px !important;
  }
  
  font-weight: 700 !important;
  line-height: 1.2 !important;
`;

const FormSection = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionLabel = styled(Text)`
  display: block;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #333333;
  margin-bottom: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  max-width: 300px;
  margin-top: 8px;

  @media (max-width: 480px) {
    max-width: 100%;
  }

  .ant-slider-mark-text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    font-size: 14px;
    color: #666666;
  }

  .ant-slider-handle {
    width: 20px;
    height: 20px;
    margin-top: -8px;
  }
`;

const ExplanationBox = styled.div`
  margin-top: 24px;
  padding: 16px;
  
  @media (min-width: 768px) {
    margin-top: 32px;
    padding: 24px;
  }
  
  background: #F9FAFB;
  border-radius: 12px;
  border: 1px solid #E5E7EB;

  .vocabulary {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #E5E7EB;
    font-style: italic;
  }
`;

const StyledTextArea = styled(TextArea)`
  font-size: 16px;
  resize: none;
  width: 100%;
  
  @media (min-width: 768px) {
    max-width: 600px;
  }
`;

const KidExplainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [age, setAge] = useState(5);
  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [error, setError] = useState(null);

  // Track page view when component mounts and handle state restoration
  useEffect(() => {
    trackPageView('kid_explainer');
    
    // Check if we have an explanation in the location state (from popup return)
    if (location.state && location.state.explanation) {
      setExplanation(location.state.explanation);
      // Clear the state to prevent showing the explanation again on refresh
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleExplain = async () => {
    setError(null);
    setLoading(true);
    
    // Track attempt to generate explanation
    trackFeatureUsage('explanation_attempt', {
      age: age,
      question_length: question.length
    });
    
    try {
      const response = await fetch('/api/kid-explainer/explain', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ age, question })
      });

      const data = await response.json();

      if (!response.ok) {
        const errorMessage = data.details || data.message || 'We couldn\'t create an explanation. Please try again.';
        
        // Track error
        trackError('explanation_generation', errorMessage, {
          status: response.status,
          age: age,
          question_length: question.length
        });
        
        setError(errorMessage);
        throw new Error(errorMessage);
      }

      // Track successful explanation generation
      trackExplanationGeneration({
        age: age,
        question_length: question.length,
        explanation_length: data.explanation.length,
        success: true
      });

      // Track feature usage on successful generation
      const currentCount = parseInt(localStorage.getItem('featureUsageCount') || '0', 10);
      const newCount = currentCount + 1;
      console.log('Feature usage count:', currentCount, '-> New count:', newCount);
      localStorage.setItem('featureUsageCount', newCount.toString());
      
      // Store last path for popup navigation
      localStorage.setItem('lastIntendedPath', '/kid-explainer');
      
      // Check if we should show popup
      const hasSeenPopup = localStorage.getItem('hasSeenSubscribePopup') === 'true';
      console.log('Has seen popup:', hasSeenPopup, 'Count threshold reached:', newCount >= 3);
      
      if (newCount >= 3 && hasSeenPopup !== 'true') {
        console.log('Showing popup and saving explanation');
        // We need to navigate to the landing page to show the popup
        // But first save the current state so we can return
        localStorage.setItem('pendingExplanation', data.explanation);
        navigate('/');
        return;
      }

      setExplanation(data.explanation);
    } catch (error) {
      console.error('Error:', error);
      message.error('We couldn\'t answer that question. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <HeaderBar>
        <LogoContainer>
          <img 
            src="/images/logo.png" 
            alt="ParentBox Logo" 
            onClick={handleLogoClick}
          />
        </LogoContainer>
      </HeaderBar>

      <MainContent>
        <FormContainer>
          <HeaderTitle level={2}>Kid Explainer</HeaderTitle>

          {error && (
            <FormSection>
              <Alert
                message="Oops! Something went wrong"
                description={error}
                type="error"
                showIcon
                closable
                onClose={() => setError(null)}
              />
            </FormSection>
          )}

          <FormSection>
            <SectionLabel>Child's Age</SectionLabel>
            <StyledSlider
              min={2}
              max={10}
              value={age}
              onChange={setAge}
              marks={{
                2: '2',
                5: '5',
                10: '10'
              }}
              tooltip={{
                formatter: value => `${value} years`
              }}
            />
          </FormSection>

          <FormSection>
            <SectionLabel>What's your child asking?</SectionLabel>
            <StyledTextArea
              value={question}
              onChange={e => setQuestion(e.target.value)}
              placeholder="Why do fireflies glow at night? Why does popcorn pop and jump when heated?"
              maxLength={150}
              autoSize={{ minRows: 2, maxRows: 4 }}
            />
          </FormSection>

          <FormSection>
            <Button
              type="primary"
              size="large"
              onClick={handleExplain}
              loading={loading}
              disabled={loading || !question.trim()}
              style={{ width: '100%', maxWidth: '300px' }}
            >
              {loading ? 'Explaining...' : 'Explain'}
            </Button>
          </FormSection>

          {explanation && (
            <ExplanationBox>
              <Paragraph style={{ fontSize: '16px', marginBottom: '16px' }}>
                {explanation}
              </Paragraph>
            </ExplanationBox>
          )}
        </FormContainer>
      </MainContent>
    </PageContainer>
  );
};

export default KidExplainer; 